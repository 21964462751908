import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, LOCALE_ID, APP_INITIALIZER } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import localePt from '@angular/common/locales/pt-PT';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeEs);
registerLocaleData(localePt);
registerLocaleData(localeFr);
import { NavbarModule } from './shared/navbar/navbar.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthInterceptorService } from './servicios/auth-interceptor.service';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgxSpinnerModule } from 'ngx-spinner';
import { RedireccionRutasWebComponent } from './shared/redireccion-rutas-web/redireccion-rutas-web.component';
import { ScrollTopModule } from './shared/scroll-top/scroll-top.module';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ServicioGeneralService } from './servicios/servicio-general.service';
import { FilterService } from 'primeng/api';
// import { MessageService } from 'primeng/api';
import { FooterComponent } from './shared/footer/footer.component';
import { SidebarModule } from './shared/sidebar/sidebar.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PlatformModule } from '@angular/cdk/platform';
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [AppComponent, RedireccionRutasWebComponent, FooterComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    NavbarModule,
    MatProgressBarModule,
    NgxSpinnerModule,
    ScrollTopModule,
    SidebarModule,
    NgbModule,
    PlatformModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
      registrationStrategy: 'registerImmediately'
    })
  ],
  providers: [
    FilterService,
    // MessageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    {
      provide: LOCALE_ID,
      deps: [ServicioGeneralService],
      useFactory: (servicioGeneral) => {
        return servicioGeneral.getLenguaje();
      }
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function appInitializerFactory(translate: TranslateService) {
  return () => {
    let langLS = localStorage.getItem('lang');
    if (!langLS) langLS = 'es-ES';
    translate.setDefaultLang(langLS);
    return translate.use(langLS).toPromise();
  };
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, `${environment.recursos}traducciones/selfbooking/`);
  // return new TranslateHttpLoader(http, './assets/i18n/');
}
