import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-redireccion-rutas-web',
  template: ''
})
export class RedireccionRutasWebComponent implements OnInit {
  constructor(private router: Router) {} // CHECK: este componente parece no usarse

  ngOnInit() {
    const urlActual = this.router.url;
    let urlRedireccion = 'https://login.consultiatravel.es/';
    switch (urlActual) {
      case '/test':
        urlRedireccion += 'TEST/index.html';
        break;
      case '/gestores':
        urlRedireccion += 'gestores/Default.aspx';
        break;
    }
    window.location.href = urlRedireccion;
  }
}
