
<div class="policies-container">
  <!-- ES -->
  <div class="seccion-idioma spanish">
    <div class="container-logo">
      <img [src]="urlLogo" alt="logo-destinux" width="100%"
      height="auto">
    </div>

    <p><span class="titulo">1.- Información de Consultia Business Travel</span></p>
    <p>El responsable y propietario de la presente página web accesible, entre otras, a través de la dirección <a
        href="http://webapp.destinux.com">webapp.destinux.com</a> , en adelante la “Web”, es Compañía Europea de Gestores de Incentivos, SL (Consultia Business Travel), en adelante la “Agencia”, con domicilio social en Sedaví (Valencia), calle San Antonio, nº 40 y CIF número B/ 98268451 con número de teléfono +34 961 961 905 y dirección de correo electrónico: <a href="mailto:sat@destinux.com">sat@destinux.com</a> .</p>
    <p>La WEB, de carácter informativo y promocional, se dirige a todos los Usuarios que estén interesados en los productos y servicios promovidos a través de esta.</p>

    <p><span class="titulo">2.- Finalidad de la WEB</span></p>
    <p>El objetivo de la WEB es la digitalización de los procesos de gestión de los viajes corporativos del cliente, así como la presentación de productos, promociones y contenidos relacionados con la agencia.</p>

    <p><span class="titulo">3.- Access to the WEB</span></p>
    <p>3.1. Por el mero acceso a la WEB, el usuario acepta sin reservas las presentes Condiciones Generales de Uso, siendo imprescindible su lectura atenta y detenida en la versión publicada por la agencia cada vez que el usuario desee acceder a la WEB.</p>
    <p>3.2. El acceso a la WEB se efectúa bajo la exclusiva responsabilidad de quien lo realiza.</p>
    <p>3.3. La presencia en Internet de la presente WEB tiene un carácter meramente voluntario, por lo que la agencia se reserva el derecho a modificar unilateralmente y en cualquier momento, sin previo aviso, la presentación o configuración de la WEB, incluyendo su supresión temporal o definitiva, sus enlaces, su dirección de acceso, sus contenidos y las presentes Condiciones Generales de Uso.</p>

    <p><span class="titulo">4.- Uso de la WEB</span></p>
    <p>El usuario se compromete a utilizar la WEB de conformidad con la ley, con las presentes Condiciones Generales de Uso y con el orden público. El usuario se obliga a abstenerse de utilizar la WEB con fines contrarios a lo establecido en estas Condiciones Generales de Uso o lesivos de los derechos e intereses de terceros, o que de cualquier forma puedan dañar, inutilizar, sobrecargar o deteriorar la WEB y sus sistemas o impedir el normal uso de la WEB por parte de los demás usuarios. De la misma forma, queda terminantemente prohibida la utilización de la WEB por parte de los usuarios de esta para explotarla con fines comerciales.</p>

    <p><span class="titulo">5.- Información que Recopilamos de Terceros</span></p>
    <p>Las plataformas de terceros (Google y Microsoft) nos proporcionan información (como su dirección de correo electrónico, su ID de usuario y su perfil público) en el momento en el que usted decide iniciar sesión en nuestra plataforma utilizando las funciones de inicio de sesión proporcionadas por dichos terceros.</p>
    <p>5.1 Información del Usuario<br>
    Al utilizar el inicio de sesión de terceros, recopilamos la siguiente información del usuario:</p>
    <ul>
      <li>Nombre</li>
      <li>Dirección de correo electrónico</li>
    </ul>
    <p>5.2 Uso de la información<br>
    La información recopilada se utiliza para:<br>
    Facilitar el inicio de sesión y la autenticación del usuario en nuestras plataformas.</p>
    <p>5.3 Limitaciones de Uso<br>
    La información del usuario se utilizará exclusivamente para los propósitos descritos en esta política de privacidad.</p>
    <p>5.4 Almacenamiento<br>
    La información obtenida del usuario a través de terceros no se almacena en nuestros sistemas, su uso es exclusivamente para los propósitos descritos en esta política de privacidad.</p>

    <p><span class="titulo">6.- Propiedad Industrial e Intelectual</span></p>
    <p>6.1. La agencia es la propietaria plena y exclusiva o, en su caso, titular de las licencias correspondientes otorgadas por terceros, en relación con todos los derechos de propiedad intelectual, derechos de propiedad industrial, derechos de dominio en Internet y asociados sobre los contenidos de cualquier otro tipo de la WEB.</p>
    <p>6.2. En consecuencia, quedan prohibidas la reproducción, la comunicación pública, el uso y la transformación o modificación de dichos contenidos fuera de dicha facultad sin la autorización previa, expresa y escrita de la agencia, incluso citando la fuente, y con independencia del carácter remunerado o no de dichas actuaciones. Cualquier infracción de los derechos mencionados anteriormente dará lugar a las responsabilidades legalmente establecidas. En particular, el Usuario se obliga a utilizar la WEB de forma diligente, correcta y lícita, no permitiéndose las siguientes actuaciones, por ilícitas:</p>
    <p>(a) Suprimir, eludir o manipular el “copyright”, marcas de la agencia o terceros y demás datos identificativos de los derechos de la agencia o de sus titulares incorporados a los contenidos y/o productos comercializados desde la WEB, así como los dispositivos técnicos de protección o cualesquiera mecanismos de información que pudieran contener los mismos.</p>
    <p>(b) Emplear los contenidos y, en particular, la información de la agencia obtenida a través de su WEB para remitir publicidad, comunicaciones con fines de venta directa o con cualquier otra clase de finalidad comercial, o mensajes no solicitados dirigidos a una pluralidad de personas.</p>
    <p>(c) Reproducir o copiar, distribuir, permitir el acceso del público a través de cualquier modalidad de comunicación pública, transformar o modificar los contenidos, a menos que se cuente con la autorización del titular de los correspondientes derechos o ello resulte legalmente permitido.</p>
    <p>(d) En general, utilizar los contenidos de forma, con fines o efectos contrarios a la ley, a la moral y a las buenas costumbres generalmente aceptadas o al orden público. La agencia no concede ninguna licencia o autorización de uso de ninguna clase sobre sus derechos de propiedad industrial e intelectual o sobre cualquier otra propiedad o derecho relacionado con su WEB.</p>
    <p>6.3. Procedimiento en caso de violación de derechos de propiedad intelectual: en el caso de que cualquier usuario de la WEB o un tercero considere que cualquiera de los contenidos ha sido introducido en la WEB con violación de sus derechos de propiedad intelectual deberá enviar una notificación a la agencia, a la dirección de email <a
        href="mailto:sat@destinux.com">sat@destinux.com</a>,&nbsp; con indicación completa y precisa de sus datos y de los concretos derechos de propiedad intelectual supuestamente infringidos.</p>

    <p><span class="titulo">7.- Responsabilidad</span></p>
    <p>7.1. La agencia no garantiza ni asume responsabilidad alguna por:</p>
    <p>(i) el funcionamiento, disponibilidad, accesibilidad o continuidad de la información, contenidos y servicios incluidos en la WEB que, según lo señalado anteriormente, podrán ser modificados en su totalidad o en parte o, incluso, suprimidos;</p>
    <p>(ii) el mantenimiento de la información, contenidos y servicios, existentes en la WEB; y</p>
    <p>(iii) su calidad, fiabilidad, idoneidad y utilidad.</p>
    <p>7.2. La agencia no controla ni garantiza la ausencia de virus ni de otros elementos en los contenidos que puedan producir alteraciones en el sistema informático, documentos electrónicos y ficheros almacenados en el sistema informático del usuario, correspondiendo a éste, en consecuencia, el adecuado uso de herramientas aptas para la detección y eliminación de los referidos elementos dañinos. Por lo tanto, la agencia no se responsabiliza de los daños producidos en los equipos informáticos de los usuarios o de terceros durante su acceso y utilización de la WEB.</p>
    <p>7.3. La agencia manifiesta y declara su buena fe en la elaboración, desarrollo y actualización de la WEB. No obstante, en el caso hipotético de que cualquier Usuario considere que alguno de los elementos y/o contenidos de la WEB infringe sus derechos o derechos de terceras partes, rogamos la inmediata comunicación de dicha circunstancia la agencia a través de alguno de los medios de contacto indicados en la WEB, con objeto de adoptar las medidas oportunas conforme a la legislación vigente.</p>

    <p><span class="titulo">8.- Política de Privacidad</span></p>
    <p>En principio, el acceso a la presente página WEB y el uso de sus contenidos no requiere que el usuario facilite dato personal alguno. En cualquier caso, en aquellas zonas o áreas dentro de la WEB en las que el usuario tenga la posibilidad voluntaria de introducir sus datos personales, le informamos de que los mismos serán incluidos en un fichero automatizado de datos titularidad de la agencia y mantenido bajo su responsabilidad. La finalidad de dicho fichero será su utilización con fines comerciales, de información y publicitarios de todo tipo de productos y servicios propios. Según el Reglamento Europeo de Protección de Datos (RGPD) dichos datos se conservarán hasta que solicite que desea dejar de recibir nuestras informaciones. En el momento que lo desee puede ejercer sus derechos de transparencia, información, acceso, rectificación, supresión o derecho al olvido, portabilidad, limitación y oposición al tratamiento de la información que le concierne y autoriza a formar parte de los tratamientos de datos personales llevados a cabo por CONSULTIA BUSINESS TRAVEL. Los derechos precitados podrán hacerse efectivos ante CONSULTIA BUSINESS TRAVEL en la dirección postal y en las direcciones de correo electrónico:</p>
    <p>DATOS DE CONTACTO:</p>
    <p>C/ San Antonio, nº 40, 46910 Sedaví (Valencia)</p>
    <p>Tel. 961 961 905.</p>
    <p>email: <a href="mailto:gdrp@consultiatravel.es">gdrp@consultiatravel.es</a></p>
    <p>Además, tiene el derecho a presentar una reclamación ante una autoridad de control como es la Agencia Española de Protección de Datos, si considera que ha existido un tratamiento ilícito relativo a sus datos personales.</p>

    <p><span class="titulo">9.- Salvaguarda de estas Condiciones Generales de Uso</span></p>
    <p>Si una de las estipulaciones de las presentes Condiciones Generales de Uso fuera declarada nula o inoperante, el resto de los términos y/o condiciones generales se mantendrán según lo acordado. En este sentido, la agencia se compromete a sustituir el término o condición general afectado por la nulidad aproximándose lo más posible a la intención inicialmente perseguida por las Partes.</p>

    <p><span class="titulo">10.- Ley aplicable y Jurisdicción competente</span></p>
    <p>9.1. Las presentes condiciones quedan sujetas a la normativa de derecho común española.</p>
    <p>9.2. Los Usuarios se someten expresamente para cualquier cuestión, discrepancia, controversia o reclamación que se derive de la ejecución, interpretación o cumplimiento de las obligaciones que se establezcan entre la agencia y los usuarios de la WEB, a la competencia de los Juzgados y Tribunales de la ciudad de Valencia, con renuncia expresa a cualquier otro fuero que por ley pudiera corresponderles.</p>

    <p><span class="titulo">11.- Copyright</span></p>
    <p>Consultia Business Travel. The use of the editorial content of this website is permitted as long as reference is
      made to its source, in addition to containing the following link: <a
        href="http://www.consultiatravel.com">www.consultiatravel.com</a></p>
    <p>Consultia Business Travel. Se permite el uso del contenido editorial de esta web siempre y cuando se haga referencia a su fuente, además de contener el siguiente enlace: www.consultiatravel.com Fotografías propiedad de Consultia Business Travel, no se permite su reutilización.</p>
  </div>
  <!-- EN -->
  <div class="seccion-idioma english">
    <div class="container-logo">
      <img [src]="urlLogo" alt="logo-destinux" width="100%"
      height="100%">
    </div>
    <p><span class="titulo">1.- Consultia Business Travel Information</span></p>
    <p>The responsible and owner of this web page accessible, among others, through the address <a
        href="http://webapp.destinux.com">webapp.destinux.com</a> , hereinafter the “Web”, is Compañía Europea de
      Gestores de Incentivos, SL (Consultia Business Travel), hereinafter the “Agency”, with registered office in Sedaví
      (Valencia), calle San Antonio, nº 40 and CIF number B/ 98268451 with telephone number +34 961 961 905 and e-mail
      address: <a href="mailto:sat@destinux.com">sat@destinux.com</a> .</p>
    <p>The WEB, of informative and promotional character, is directed to all the Users who are interested in the products
      and services promoted through this one.</p>

    <p><span class="titulo">2.- Purpose of the WEBSITE</span></p>
    <p>The purpose of the WEB is the digitalization of the processes of management of the client’s corporate trips, as
      well as the presentation of products, promotions and contents related to the agency.</p>

    <p><span class="titulo">3.- Access to the WEB</span></p>
    <p>3.1. By the mere access to the WEB, the user accepts without reservation these General Conditions of Use, being
      essential to read carefully and carefully in the version published by the agency each time the user wishes to access
      the WEB.</p>
    <p>3.2. The access to the WEB is made under the exclusive responsibility of the user.</p>
    <p>3.3. The presence in Internet of the present WEB has a merely voluntary character, reason why the agency reserves
      the right to modify unilaterally and at any time, without previous warning, the presentation or configuration of the
      WEB, including its temporary or definitive suppression, its links, its access address, its contents and the present
      General Conditions of Use.</p>

    <p><span class="titulo">4.- Use of the WEBSITE</span></p>
    <p>The user undertakes to use the WEBSITE in accordance with the law, with these General Conditions of Use and with
      public order. The user undertakes to refrain from using the WEB for purposes contrary to the provisions of these
      General Conditions of Use or harmful to the rights and interests of others, or in any way damage, disable,
      overburden, or impair the WEB and its systems or impede the normal use of the WEB by other users. In the same way,
      it is strictly forbidden the use of the WEB by the users of this to exploit it for commercial purposes.</p>

    <p><span class="titulo">5.- Information We Collect from Third Parties</span></p>
    <p>Third-party platforms (Google and Microsoft) provide us with information (such as your email address, user ID, and public profile) at the time you choose to log in to our platform using the sign-in features provided by those third parties.</p>
    <p>5.1 User Information<br>
    When using third-party login, we collect the following information from the user:</p>
    <ul>
      <li>Name</li>
      <li>Email Address</li>
    </ul>
    <p>5.2 Use of Information<br>    
    The information collected is used to:<br>
    Facilitate user login and authentication on our platforms.</p>
    <p>5.3 Limitations on Use<br>
    User information will be used exclusively for the purposes described in this privacy policy.</p>
    <p>5.4 Storage<br>
    Information obtained from the user through third parties is not stored in our systems, its use is exclusively for the purposes described in this privacy policy.</p>

    <p><span class="titulo">6.- Industrial and Intellectual Property</span></p>
    <p>6.1. The agency is the full and exclusive owner or, as the case may be, holder of the corresponding licenses
      granted by third parties, in relation to all intellectual property rights, industrial property rights, domain rights
      on the Internet and associated rights over the contents of any other type of the WEBSITE.</p>
    <p>6.2. Consequently, the reproduction, public communication, use and transformation or modification of such content
      outside this faculty is prohibited without the prior, express and written authorisation of the agency, including
      citing the source, and regardless of the remunerated or non-remunerated nature of such actions. Any infringement of
      the above-mentioned rights will give rise to the legally established liabilities. In particular, the User undertakes
      to use the WEBSITE diligently, correctly and lawfully, and the following actions are not permitted, as unlawful:</p>
    <p>(a) Delete, elude or manipulate the copyright, trademarks of the agency or third parties and other data identifying
      the rights of the agency or its owners incorporated into the contents and/or products marketed from the WEBSITE, as
      well as the technical protection devices or any information mechanisms that may contain them.</p>
    <p>(b) Use the contents and, in particular, the information obtained from the agency through its website to send
      advertising, communications for direct sales purposes or for any other type of commercial purpose, or unsolicited
      messages addressed to a plurality of people.</p>
    <p>(c) Reproducing or copying, distributing, allowing public access through any form of public communication,
      transforming or modifying the contents, unless authorized by the owner of the corresponding rights or legally
      permitted.</p>
    <p>(d) In general, to use the contents in a manner, for purposes or effects contrary to the law, morality and
      generally accepted good customs or public order. The agency does not grant any licence or authorisation of use of
      any kind on its industrial and intellectual property rights or on any other property or right related to its
      WEBSITE.</p>
    <p>6.3. Procedure in case of infringement of intellectual property rights: in the event that any user of the WEBSITE
      or a third party considers that any of the content has been introduced on the WEBSITE in violation of their
      intellectual property rights, they must send a notification to the agency, to the email address <a
        href="mailto:sat@destinux.com">sat@destinux.com</a>,&nbsp; with a complete and precise indication of your details
      and the specific intellectual property rights allegedly infringed.</p>

    <p><span class="titulo">7.- Liability</span></p>
    <p>7.1. The Agency does not guarantee or assume any responsibility for:</p>
    <p>(i) the operation, availability, accessibility or continuity of the information, content and services included in
      the WEBSITE which, as indicated above, may be modified in whole or in part or even deleted;</p>
    <p>(ii) the maintenance of the information, content and services existing on the WEBSITE; and</p>
    <p>(iii) its quality, reliability, suitability and usefulness.</p>
    <p>7.2. The agency does not control or guarantee the absence of viruses or other elements in the contents that may
      cause alterations in the computer system, electronic documents and files stored in the user’s computer system, and
      it is therefore up to the user to make appropriate use of tools suitable for the detection and elimination of the
      aforementioned harmful elements. Therefore, the agency is not responsible for any damage caused to the computer
      equipment of users or third parties during their access to and use of the WEBSITE.</p>
    <p>7.3. The Agency declares and declares its good faith in the elaboration, development and updating of the WEBSITE.
      However, in the hypothetical event that any User considers that any of the elements and/or contents of the WEBSITE
      infringes their rights or the rights of third parties, we ask the agency to immediately notify the agency of this
      circumstance through any of the means of contact indicated on the WEBSITE, in order to adopt the appropriate
      measures in accordance with current legislation.</p>

    <p><span class="titulo">8.- Privacy Policy</span></p>
    <p>In principle, access to this website and the use of its contents do not require the user to provide any personal
      data. In any case, in those areas or areas within the WEBSITE in which the user has the voluntary possibility of
      entering their personal data, we inform them that they will be included in an automated data file owned by the
      agency and maintained under its responsibility. The purpose of this file will be to use it for commercial,
      information and advertising purposes for all kinds of own products and services. In accordance with the European
      Data Protection Regulation (GDPR), such data will be retained until you request that you no longer receive
      information from us. At any time, you can exercise your rights of transparency, information, access, rectification,
      deletion or right to be forgotten, portability, limitation and opposition to the processing of information that
      concerns you and authorises you to be part of the processing of personal data carried out by CONSULTIA BUSINESS
      TRAVEL. The aforementioned rights may be exercised before CONSULTIA BUSINESS TRAVEL at the postal address and at the
      following email addresses:</p>
    <p>CONTACT DETAILS:</p>
    <p>C/ San Antonio, nº 40, 46910 Sedaví (Valencia)</p>
    <p>Tel. 961 961 905.</p>
    <p>email: <a href="mailto:gdrp@consultiatravel.es">gdrp@consultiatravel.es</a></p>
    <p>In addition, you have the right to file a complaint with a supervisory authority such as the Spanish Data
      Protection Agency, if you consider that there has been unlawful processing of your personal data.</p>

    <p><span class="titulo">9.- Safeguarding of these General Conditions of Use</span></p>
    <p>If one of the stipulations of these General Terms and Conditions of Use is declared null and void or inoperative,
      the rest of the general terms and/or conditions will remain as agreed. In this sense, the agency undertakes to
      replace the general term or condition affected by the nullity by coming as close as possible to the intention
      initially pursued by the Parties.</p>

    <p><span class="titulo">10.- Applicable Law and Competent Jurisdiction</span></p>
    <p>9.1. These conditions are subject to Spanish common law.</p>
    <p>9.2. Users expressly submit to the jurisdiction of the Courts and Tribunals of the city of Valencia for any
      question, discrepancy, controversy or claim arising from the execution, interpretation or fulfilment of the
      obligations established between the agency and the users of the WEBSITE, expressly waiving any other jurisdiction
      that may correspond to them by law.</p>

    <p><span class="titulo">11.- Copyright</span></p>
    <p>Consultia Business Travel. The use of the editorial content of this website is permitted as long as reference is
      made to its source, in addition to containing the following link: <a
        href="http://www.consultiatravel.com">www.consultiatravel.com</a></p>
    <p>Photographs owned by Consultia Business Travel are not allowed to be reused.</p>
  </div>
</div>