<div class="conditions-container">
  <div class="seccion-idioma spanish">
    <div class="container-logo">
      <img [src]="urlLogo" alt="logo-destinux" width="100%" height="auto">
    </div>

    <p class="titulo">CONDICIONES GENERALES E INFORMACIÓN RELEVANTE</p>
    <p>&nbsp;</p>
      <p class="titulo">1. CONDICIONES DE ACCESO Y UTILIZACIÓN</p>
    <p>&nbsp;</p>
    <p >1.1. Acceso a la web, contenidos e información</p>
    <p>El acceso a esta página web así como el uso de la contraseña es responsabilidad exclusiva de los usuarios.</p>
    <p>Compañía Europea de gestores de Incentivos SL, en adelante “Consultia Business Travel”, se reserva el derecho de
      realizar, en cualquier momento, cambios y modificaciones sin necesidad de previo aviso.</p>
    <p>El Usuario declara ser mayor de edad y tener plena capacidad; asimismo garantiza la autenticidad y veracidad de
      los datos que comunique, siendo de su responsabilidad el actualizar la información suministrada.</p>
    <p>&nbsp;</p>
    <p>1.2. Obligación de hacer uso correcto de la página web y de los contenidos</p>
    <p>El Usuario se compromete a la correcta utilización de la web y utilidades que se le proporcionen conforme a la
      ley, el presente aviso legal, y las instrucciones y avisos que se le comuniquen, para fines lícitos y no
      prohibidos.</p>
    <p>El Usuario deberá abstenerse de obtener e incluso de intentar obtener los Contenidos.</p>
    <p>&nbsp;</p>
    <p>1.3. Derechos de propiedad industrial e intelectual</p>
    <p>El Usuario se obliga a respetar los derechos de propiedad industrial e Intelectual de Consultia Business Travel y
      las empresas del grupo al que pertenece y de cualquier otro tercero. El uso o la concesión de acceso a la página
      Web no comporta el otorgamiento de derecho alguno sobre las marcas, nombres comerciales o cualquier otro signo
      distintivo que se utilicen en la misma.</p>
    <p>&nbsp;</p>
    <p>1.4. Duración deI servicio</p>
    <p>Consultia Business Travel no garantiza la disponibilidad y continuidad del funcionamiento del Sitio web.</p>
    <p>&nbsp;</p>
      <p class="titulo">2. EXCLUSIÓN DE RESPONSABILIDAD</p>
    <p>Consultia Business Travel excluye cualquier responsabilidad por daños y perjuicios de cualquier naturaleza
      eventualmente derivados de la web.</p>
    <p>&nbsp;</p>
      <p class="titulo">3. PROTECCIÓN DE DATOS, SEGURIDAD Y PRIVACIDAD</p>
    <p>Consultia Business Travel cumple con la normativa sobre protección de datos personales, la cual está contenida en
      el siguiente <a href="http://webapp.destinux.com/privacy-policy">LINK</a></p>
    <p>&nbsp;</p>
      <p class="titulo">4. ACEPTACION DE GASTOS DE GESTION Y CARGOS DE EMISION</p>
    <p>La reserva de servicios puede conllevar la aceptación de unos cargos de emisión. Este importe no es reembolsable
      en ningún caso y es independiente de la tarifa seleccionada por el comprador. En caso de cambios o modificaciones
      igualmente se aplicará un cargo.</p>
    <p>&nbsp;</p>
      <p class="titulo">5. TRÁMITES PARA ADQUIRIR UN SERVICIO DE VIAJE.</p>
    <p>Para la adquisición de un servicio en la página WEB de Consultia Business Travel será necesario solicitar la
      inclusión como usuario cliente o pertenecer como usuario autorizado a una empresa que haya gestionado el alta como
      cliente manifestando su aceptación a las condiciones de uso y pago establecidas.</p>
    <p>&nbsp;</p>
      <p  class="titulo">6. RESERVA Y COMPRA DE PRODUCTOS Y SERVICIOS</p>
    <p>El servicio de reserva de cualquiera de los productos ofertados a través de la página WEB de Consultia Business
      Travel conlleva la aceptación de las condiciones particulares que de ese servicio que el prestador del servicio
      haya establecido.</p>
    <p>&nbsp;</p>
      <p class="titulo">7. ENTREGA DE LA DOCUMENTACIÓN DE VIAJE</p>
    <p>Los billetes, bonos, etc. se enviarán por e-mail, una vez efectuada la reserva en nuestra web, o siempre quedarán
      integrados en la Agenda de usuario a disponibilidad del viajero.</p>
    <p>Las indicaciones realizadas en las siguientes secciones en cuanto a envíos, emisiones, compras y reservas, y
      documentación, se consideran genéricas y se muestran sólo a título informativo.</p>
    <p>&nbsp;</p>
      <p class="titulo">8. RESERVAS AEREAS</p>
    <p>Para obtener una tarifa visualizada es necesario realizar la reserva inmediatamente después de haber realizado la
      consulta, ya que los precios pueden variar en todo momento.</p>
    <p>Aquellas reservas realizadas con una aerolínea distinta a la que opera el vuelo pueden no admitir la inclusión de
      servicios adicionales. En este caso, consulta la posibilidad de contratar estos servicios a través de su Personal
      Travel Asistant.</p>
    <p>&nbsp;</p>
    <p>8.1. Reservas con aerolíneas low-cost</p>
    <p>Si realiza una reserva de vuelo con una aerolínea de las denominadas low-cost deberá tener en cuenta que estas
      reservas requieren que realice el check-in online y presente la tarjeta de embarque impresa en el aeropuerto, y
      que cualquier trámite de cancelación o cambio deberá ser realizado por el pasajero o titular de la reserva
      contactando directamente con su Personal Travel Asistant.</p>
    <p>La normativa interna de las compañías aéreas de bajo coste obliga que todos los menores de 14 años vuelen siempre
      acompañados por un adulto y debidamente documentados. Algunas compañías aéreas entre las que se incluyen las de
      bajo coste no ofrecen el servicio de asistencia a menores de 14 años.</p>
    <p>Recuerde consultar directamente con la compañía aérea sus condiciones de equipaje</p>
    <p>&nbsp;</p>
    <p>8.2. Tasas de Aeropuerto</p>
    <p>Las Tasas generalmente están incluidas, excepto cuando las tasas de aeropuertos locales, o las tasas o impuestos
      gubernamentales se cobran al pasajero en el mismo aeropuerto, o se especifican en las condiciones de la tarifa.
    </p>
    <p>&nbsp;</p>
    <p>8.3. Documentación de vuelo</p>
    <p>Las compañías aéreas exigen la presentación del DNI o Pasaporte a todos los pasajeros y en todos los vuelos, se
      ruega consultar la recomendación de viajes con menores en www.maec.es. Es responsabilidad del cliente informarse y
      cumplir con los requisitos necesarios para realizar el viaje (pasaporte, visado, vacunas, o inclusive presentar el
      billete del vuelo de regreso en caso de adquirir sólo el de ida, etc.).</p>
    <p>&nbsp;</p>
    <p>8.4. Datos de vuelo</p>
    <p>Para confirmar reserva, es necesario incluir los datos personales con el fin de poder informar de cualquier
      modificación, etc. sobre la reserva.</p>
    <p>Es muy importante escribir los nombres y apellidos tal y como figuran en el pasaporte o DNI, no siendo
      responsabilidad de la agencia de viajes la inclusión de datos erróneos.</p>
    <p>Si el nombre del pasajero tal cómo aparece escrito en su documentación (DNI o pasaporte) supera el espacio
      posible, es necesario ponerse en contacto con Consultia Business Travel online.</p>
    <p>Al finalizar el proceso de reserva de manera correcta, la página Web facilitará un localizador de reserva y se
      procederá a facturar el servicio y la reserva será una compra.</p>
    <p>&nbsp;</p>
    <p>8.5. Notificación de cambios en Ia reserva</p>
    <p>Cualquier tipo de cambio de horario, cancelación o modificación de los vuelos contratados es responsabilidad de
      la compañía aérea que actúa de transportista del propio pasajero. La forma de aviso al cliente será vía e-mail. Es
      necesario reconfirmar el estado de los vuelos 48 horas antes de la salida y regreso, contactando directamente con
      la compañía aérea.</p>
    <p>&nbsp;</p>
    <p>8.6. Modificaciones o Anulaciones</p>
    <p>Si se desea realizar alguna modificación sobre la reserva ya realizada, se debe hacer contactando con su Personal
      Travel Assistant en Consultia Business Travel, o enviando un e-mail o llamada telefónica.</p>
    <p>&nbsp;</p>
    <p>8.7. Dto. Residente Balear/Canario/Ceuta/Melilla</p>
    <p>se debe hacer contactando Consultia Business Travel ON LINE enviando un e-mail o llamada telefónica a su Personal
      Travel Assistant.</p>
    <p>&nbsp;</p>
    <p>8.8. Menores</p>
    <p>A efectos de transporte aéreo, menor es la persona que no ha cumplido los 18 años de edad en la fecha del vuelo,
      estableciéndose los siguientes tramos.</p>
    <p>En viajes nacionales, los menores deben viajar siempre debidamente documentados.</p>
    <p>En Viajes internacionales de menores de edad, se deben cumplir los requisitos marcados por la norma vigente para
      la salida del menor según país y circunstancia personal.</p>
    <p>Todas las peticiones de viajes para menores, sean o no acompañados, tendrán que hacerse bajo petición especial.
    </p>
    <p>&nbsp;</p>
      <p class="titulo">9. RESERVAS NO AEREAS</p>
    <p>A efectos de reservas del resto de servicios (reservas hoteleras, tren, alquiler de vehículos, etc.) se debe
      consultar las condiciones establecidas en cada caso para la consideración de menor y las tarifas aplicables.</p>
    <p>&nbsp;</p>
    <p>9.1. Condiciones generales compañías de alquiler de vehículos</p>
    <p>Todos los conductores tienen que tener al menos 21 años y poseer un carnet de conducir válido durante al menos un
      año al inicio del alquiler (2 años si la reserva es con Sixt).</p>
    <p>&nbsp;</p>
    <p>9.2. TREN</p>
    <p>Tenga en cuenta que en algunos trenes el acceso al andén se cierra minutos antes de la salida del tren.</p>
    <p>Para cualquier modificación de los mismos deberá ponerse en contacto con su Personal Travel Asisstant.</p>
    <p>También puede imprimir el billete que haya comprado, introduciendo el localizador o tarjeta de pago en las
      máquinas auto check-in instaladas en las estaciones (hasta 5 minutos antes de la salida del tren de la estación de
      origen del viaje) o en las taquillas de las estaciones.</p>
    <p>El punto de control que permite el acceso al tren se cierra 2 minutos antes de la salida del tren. No obstante,
      en estaciones grandes y en todas aquellas con escáner para equipajes es aconsejable llegar con al menos 15 minutos
      de antelación.</p>
    <p>&nbsp;</p>
    <p>9.3. HOTELES</p>
    <p>&nbsp;</p>
    <p>Búsqueda de hoteles</p>
    <p>El precio será definitivo cuando se obtenga el localizador de la reserva.</p>
    <p>Una vez confirmada su reserva, para cualquier modificación o cancelación, debe contactar con Consultia Business
      Travel ON LINE.</p>
    <p>&nbsp;</p>
    <p>Confirmación de reserva de hotel</p>
    <p>En el momento de cerrar una reserva de hotel el cliente recibe un e-mail con los datos del hotel reservado.</p>
    <p>&nbsp;</p>
    <p>Horarios de entrada y salida del establecimiento</p>
    <p>En el caso de los hoteles, el horario internacional de entrada y salida son las 12 horas, pero algunos
      establecimientos pueden modificar esta norma.</p>
    <p>El horario más habitual de entrada en los apartamentos está estipulado a partir de las 17.00 horas, siendo la
      salida antes de las 10.00 horas. El horario de oficina para la recogida de llaves es habitualmente de 17.00 horas
      a 20.00 horas.</p>
    <p>Algunos establecimientos pueden requerir en el momento de efectuar la reserva o durante la estancia, un depósito
      y/o fianza.</p>
    <p>&nbsp;</p>
    <p>Solicitud tarjeta de crédito</p>
    <p>En la mayoría de los establecimientos hoteleros pueden solicitarle una tarjeta de crédito, a la llegada, como
      garantía ante los extras que pudiese realizar durante su estancia.</p>
    <p>&nbsp;</p>
    <p>Pensiones alimenticias</p>
    <p>En el caso de estar incluida en el precio, generalmente las bebidas (incluso el agua) no están incluidas.</p>
    <p>El establecimiento puede modificar sin previo aviso el servicio de comidas; por lo general la media pensión
      consiste en desayuno y cena. Si por causas de retraso, sea cual fuese, se perdiera algún servicio de restaurante
      correspondiente a la pensión alimenticia contratada, no existirá derecho a devolución. Los pasajeros alojados
      gratuitamente en camas supletorias o cunas pueden no tener incluido el régimen alimenticio.</p>
    <p>&nbsp;</p>
    <p>Adaptado para personas con movilidad reducida</p>
    <p>En la descripción de los establecimientos encontrará aquellos que se encuentran adaptados en habitaciones y zonas
      comunes, para personas con movilidad reducida.</p>
    <p>&nbsp;</p>
    <p>Oferta Promoción</p>
    <p>Cualquier oferta o promoción que pudiesen realizar los establecimientos hoteleros con posterioridad, no tendrán
      carácter retroactivo.</p>
    <p>Las ofertas publicadas consistentes en un número de noches de estancia, pagando un número inferior, llevarán
      gratuita/s la/s ultima/s noche/s, salvo indicación en contra en el apartado de observaciones de cada
      establecimiento.</p>
    <p>Las ofertas no son acumulables, salvo indicación expresa.</p>
    <p>&nbsp;</p>
    <p>Categoría oficial</p>
    <p>En cada establecimiento aparece publicada la categoría que ostenta en el momento de la contratación del mismo.
    </p>
    <p>&nbsp;</p>
      <p class="titulo">10. TASAS TURÍSTICAS O LOCALES</p>
    <p>En algunos destinos requieren el pago de la llamada «Tasa turística», «Tasa local» o similar («Ecotasa», «Tasa
      por la prestación de servicios adicionales», etc.) que se paga directamente en el establecimiento reservado.</p>
    <p>&nbsp;</p>
      <p class="titulo">11. SERVICIO DE URGENCIAS 24 HORAS</p>
    <p>Consultia Business Travel pone a su disposición un servicio de urgencias 24/7 para aquellos trámites que han de
      realizarse de manera urgente, fuera de nuestro horario de oficina.</p>
    <p>Dicho servicio de Urgencias le atenderá de Lunes a Viernes desde las 18.01 horas, hasta las 08:59 horas, así como
      las 24 horas en fines de semana y festivos.</p>
    <p>Dicho servicio tiene un coste adicional de 42.35€ (IVA INCLUIDO) POR CONSULTA/INTERVENCIÓN y de 30.25€ (IVA
      INCLUIDO) POR EMISIÓN DE TARJETA DE EMBARQUE</p>

  </div>
</div>