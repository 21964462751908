import { environment } from '../../environments/environment'; //variable globales
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private userLoggedIn = new BehaviorSubject(false);

  getLoggedIn(): Observable<boolean> {
    return this.userLoggedIn.asObservable();
  }

  getLoggedInValue(): boolean {
    return this.userLoggedIn.getValue();
  }

  setLoggedIn(val: boolean) {
    this.userLoggedIn.next(val);
  }

  constructor(private http: HttpClient, private router: Router, private authService: AuthService) {}

  PostLogin(log_user, token = null, provider = null) {
    const credencialesDemo = {
      dni: '12345678Z',
      password: '123456789'
    };
    if (environment.demo) {
      if (JSON.stringify(log_user) != JSON.stringify(credencialesDemo)) {
        return throwError(
          new HttpErrorResponse({
            error: 'CredencialesKo',
            status: 401,
            statusText: 'Error'
          })
        );
      } else {
        return this.http.get('assets/misc/respuestas_API/login/res_login.json', { observe: 'response' });
      }
    } else {
      const httpOptions = {
          'Content-Type': 'application/x-www-form-urlencoded'
      };

      let peticion = `username=${log_user.dni}&password=${encodeURIComponent(log_user.password)}&grant_type=password`;
      if(token !== null)
      {
        peticion = `Token=${token}&Provider=${provider}&grant_type=password`;
      }
      return this.http.post<any>(
        `/api/login`, peticion,
         { headers:httpOptions, observe: 'response'}
        )
    }
  }

  AuthMicrosoft(token: string, provider: string)
  {
    var body = {
      token,
      provider
    }
    return this.http.post(`/api/auth/login`,body);
  }

  async GetLogOut(datosCaducados: boolean) {
    var authMicrosoft = localStorage.getItem('authMicrosoft');
    this.authService.setUltimaAccion(null);
    this.setLoggedIn(false);
    localStorage.clear();
    if (authMicrosoft == "true") {
      await this.logoutAzure().then().catch(error => console.log(''));
    }
    this.router.navigateByUrl(`/login${datosCaducados ? '/r' : ''}`, { state: { sesionCaducada: datosCaducados } });
  }

  GetInfoUser() {
    if(environment.demo){
      return this.http.get(`assets/misc/respuestas_API/login/res_DatosPerfil.json`);
    } else{
      return this.http.get('/api/DatosPerfil');
    }
  }

  isUserAuthenticated() {
    if (!localStorage.getItem('user_token')) {
      return false;
    }
    return true;
  }

  public getVersionApis() {
    if (environment.demo){
      return this.http.get('assets/misc/respuestas_API/nueva_versionAPIs_mantenimiento.json');
    }
    return this.http.get('version/Select');
  }
  
  getCorreoProvider(): Promise<any> {
    return this.http.get(environment.baseUrl + "/.auth/me").toPromise();
  }

  logoutAzure(): Promise<any> {
    return this.http.get(environment.baseUrl + "/.auth/logout").toPromise();
  }
}
