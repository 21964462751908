import { Pipe, PipeTransform } from '@angular/core';
import { IPrefijoTelefonoInternacional } from '../../modelos/comunes/comunes.model';

@Pipe({
  name: 'filtro'
})

/**
 * Clase que permite realizar el filtro de las opciones de selector
 */
export class FiltroSelectPrefijosIntPipe implements PipeTransform
{
  /**
   * Método que permite realizar la busqueda de un texto en la lista de opciones
   * @param opciones Lista de opciones
   * @param textoBusqueda Texto a buscar en las opciones
   * @returns Lista filtrada
   */
  transform(opciones: IPrefijoTelefonoInternacional[], textoBusqueda: string): IPrefijoTelefonoInternacional[] {
    if(!opciones || !opciones.length) return opciones;
    if(!textoBusqueda || !textoBusqueda.length) return opciones;
    let resultados = [];
    
    resultados = opciones.filter((item) => {
      return item.viewValue.toLowerCase().includes(textoBusqueda.toLowerCase());
    });  
   
      return resultados.length > 0 ? resultados : [{value: '', viewValue: 'admin.comun.sin-resultados-titulo'}];
   
  }

}
