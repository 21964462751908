import { Pipe, PipeTransform } from '@angular/core';
/*
 * Hace mayúscula la primera letra de una cadena
 * previamente transformada a minúsculas.
 * Toma un string como valor.
 * Uso:
 *  valor | capitalLetterFirst
 * Ejemplo:
 *  // complemento.Nombre = 'UNACCOMPANIED MINOR'
 *  {{ complemento.Nombre | capitalLetterFirst : defaultValue  }}
 *  formatea a: Unaccompanied minor
*/
@Pipe({
    name: 'capitalLetterFirst'
})
export class CapitalLetterFirst implements PipeTransform {
    transform(value: string, args: any[]): string {
        if (value === null) return args[0] || '';
        value = value.toLowerCase();
        return value.charAt(0).toUpperCase() + value.slice(1);
    }
}
