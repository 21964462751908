import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-condiciones-generales',
  templateUrl: './condiciones-generales.component.html',
  styleUrls: ['./condiciones-generales.component.scss']
})
export class CondicionesGeneralesComponent implements OnInit {
  urlLogo = environment.recursos + '/nuevoestiloweb/logos/logo_tipos_destinux.svg';

  constructor() { }

  ngOnInit(): void {
  }

}
