import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HorasMinutosPipe } from './HorasMinutosPipe';
import { SafePipe } from './SafePipe';
import { HighlightPipe } from './highlight.pipe';
import { DiasEntreFechasPipe } from './diasEntreFechas.pipe';
import { CapitalLetterFirst } from './capitalLetterFirst.pipe';
import { FiltroSelectPrefijosIntPipe } from './filtroSelectPrefijosInt.pipe';

@NgModule({
  declarations: [
    HorasMinutosPipe, 
    SafePipe, 
    HighlightPipe, 
    DiasEntreFechasPipe, 
    CapitalLetterFirst, 
    FiltroSelectPrefijosIntPipe],
  imports: [
    CommonModule
  ],
  exports: [
    HorasMinutosPipe, 
    SafePipe, 
    HighlightPipe, 
    DiasEntreFechasPipe, 
    CapitalLetterFirst, 
    FiltroSelectPrefijosIntPipe]
})
export class PipesModule { }
