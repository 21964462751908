import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import * as dayjs from 'dayjs';
import Litepicker from 'litepicker';
import { Pais } from 'src/app/modelos/pais';
import { IItem } from 'src/app/modelos/comunes/comunes.model';
import { EMensaje } from 'src/app/enumerados/enumerados.enum';
import { AnyForUntypedForms } from '@angular/forms';
export class Funciones {
    
  public static detectIE() {
    return /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    // var ua = window.navigator.userAgent;
    // var msie = ua.indexOf('MSIE ');
    // if (msie > 0) {
    //   return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    // }

    // var trident = ua.indexOf('Trident/');
    // if (trident > 0) {
    //   var rv = ua.indexOf('rv:');
    //   return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    // }

    // return false;
  }
  public static getHotelStars(categoria: number) {
    let array = [];
    if (categoria != null) {
      array = new Array(categoria);
    }
    return array;
  }

  public static getAge(date: Date) {
    const today = new Date();
    let age = today.getFullYear() - date.getFullYear();
    const m = today.getMonth() - date.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < date.getDate())) {
      age--;
    }
    return age;
  }

  public static b64toBlob(b64Data, contentType) {
    contentType = contentType || '';
    var sliceSize = 512;
    b64Data = b64Data.replace(/^[^,]+,/, '');
    b64Data = b64Data.replace(/\s/g, '');
    var byteCharacters = window.atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, {
      type: contentType
    });
    return blob;
  }

  public static dateToUTC(fecha: Date) {
    return new Date(Date.UTC(fecha.getFullYear(), fecha.getMonth(), fecha.getDate(), 0, 0, 0));
  }

  public static tamanoPantalla() {
    let tam = 0;
    if (window.matchMedia('(max-width: 639px)').matches) {
      tam = 1;
    } else if (window.matchMedia('(min-width: 640px)').matches) {
      tam = 2;
    } else {
      tam = 3;
    }

    return tam;
  }

  public static abrirLink(url: string) {
    const win = window.open(url);

    if (win) {
      win.focus();
    } else {
      alert('Por favor, permita abrir popups de esta web.');
    }
  }

  public static getDatePlusDays(date: Date, daysMore: number) {
    const finalDate: Date = new Date(date);
    finalDate.setDate(finalDate.getDate() + daysMore);
    return finalDate;
  }

  public static dividirEnteroDecimal(numero: number) {
    const partesNumero = numero.toFixed(2).split('.');
    return { parteEntera: partesNumero[0], parteDecimal: partesNumero[1] };
  }

  public static abrirSwalAgredecimiento(titulo) {
    Swal.fire({
      icon: 'success',
      title: titulo,
      showConfirmButton: false,
      timer: 2000
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.timer) {
        window.location.reload();
      }
    });
  }

  public static descargarBlob(blob: Blob, nombreFichero: string) {
    if (this.detectIE()) {
      const windowNavigator: any = window.navigator;
      if (windowNavigator.msSaveOrOpenBlob) {
        windowNavigator.msSaveOrOpenBlob(blob, nombreFichero);
      }
    } else {
      let dataType = blob.type;
      const downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(new Blob([blob], { type: dataType }));
      downloadLink.setAttribute('download', nombreFichero);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      downloadLink.parentNode.removeChild(downloadLink);
    }
  }

  public static setSwalConfirmacionReserva(flag: boolean, title?: string, description?: string) {
    if (flag) {
      Swal.fire({
        title: title,
        html:
          '<h5>' +
          description +
          '...</h5>' +
          '<br>' +
          '<i style="margin-top:0.1rem;" class="fas fa-spinner fa-pulse fa-3x"></i>',
        allowOutsideClick: false,
        showCloseButton: false,
        showConfirmButton: false,
        padding: '3em',
        backdrop: 'rgba(0,0,0,0.85)'
      });
    } else {
      Swal.close();
    }
  }

  public static eliminarReferenciaVariable(variable: any) {
    const variableSinRef = JSON.parse(JSON.stringify(variable));
    return variableSinRef;
  }

  public static inicializarViajerosSeleccionables() {
    const ramas = JSON.parse(localStorage.getItem('info_user')).ramas;
    return ramas.filter((rama) => {
      return rama.SoloAgenda == false;
    });
  }

  public static swapFondoPantalla(activo: boolean, urlImagen?: string) {
    if (!activo) {
      document.body.style.backgroundImage = `url('${urlImagen}')`;
    } else {
      document.body.style.backgroundImage = '';
    }
  }

  public static getContentType(extension: string) {
    let contentType = '';
    switch (extension) {
      case 'pdf':
        contentType = 'application/pdf';
        break;

      case 'xlsx':
        contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        break;

      case 'docx':
        contentType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        break;

      case 'png':
        contentType = 'image/png';
        break;

      default:
        break;
    }
    return contentType;
  }

  public static getFormatedName(name: string) {
    let res = name.replace(',', '');
    let firstName = res
      .split(' ')
      .slice(0, 1)
      .join(' ');
    let lastName = res
      .split(' ')
      .slice(-2)
      .join(' ');
    return `${lastName}, ${firstName}`;
  }

  public static getDate(fecha: string | Date) {
    return fecha ? dayjs(fecha).format('YYYY-MM-DD') : null;
  }

  public static getNombrePais(codigoISO3: string, paises: Pais[]) {
    return paises.find((pais) => pais.alpha3.toUpperCase() == codigoISO3)?.name;
  }

  public static eliminarContenedorActivo() {
    const contenedorActivo = document.getElementsByClassName('contenedor-input--activo');
    if (contenedorActivo.length > 0) contenedorActivo[0].classList.remove('contenedor-input--activo');
  }

  public static scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  public static checkDatesAreBeforeNow(dates: any[]) {
    const hoy = dayjs();
    return dates.every((date) => hoy.isBefore(dayjs(date)));
  }

  public static getDia(fecha: Date | string, lenguaje): string {
    return new Date(fecha).toLocaleDateString(
      lenguaje,
      {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      }
    )
  }

  /** params: "yyyy-MM-dd HH:mm" or "yyyy-MM-ddTHH:mm" */
  public static calcularTotalDiasEntreFechas(fechaInicial: string, fechaFinal: string): number {
    let result = null;
    if (fechaInicial && fechaFinal) {
      const fechaIncialFormateada = dayjs(fechaInicial.replace(' ', 'T'));
      const fechaFinalnFormateada = dayjs(fechaFinal.replace(' ', 'T'));
      result = fechaFinalnFormateada.diff(fechaIncialFormateada, 'days');
    }
    return result;
  }

  /** FORMATO EUROPEO */
  public static getDateTimeNoUSA(fecha: string | Date) {
    return fecha ? dayjs(fecha).format('DD-MM-YYYY HH:mm') : null;
  }
  /** FORMATO EUROPEO */
  public static getDateNoUSA(fecha: string | Date) {
    return fecha ? dayjs(fecha).format('DD-MM-YYYY') : null;
  }

  /** ejemplo params: 24.5,"es-ES","EUR", 2 */
  /** ejemplo output: '24,50' */
  public static formatearPrecioToLocale(precio: number, idioma: string = 'es-ES', moneda: string = 'EUR', decimales: number = 2): string {
    precio = precio ?? 0;
    return precio.toLocaleString(idioma, { style: 'currency', currency: moneda, minimumFractionDigits: decimales, maximumFractionDigits: decimales });
  }

  public static existeValorEnEnum(value, enumList): boolean {
    for (const k in enumList) if (enumList[k] == value) return true; return false;
}

  public static calcularNoches(fechaInicio,fechaFin):number {
    const fechas = {
      inicio: dayjs(fechaInicio),
      fin: dayjs(fechaFin)
    };
    return fechas.fin.diff(fechas.inicio, 'days');
  }

  public static limpiarEspacios(texto:string):string{
    return texto.replace(/\s/g,'');
  }

  /** ejemplo params: 'UNACCOMPANIED MINOR' */
  /** ejemplo output: 'Unaccompanied minor' */
  // Para utilizar en los valores de los atributos tooltip
  // ya que no se puede usar el pipe capitalLetterFirst
  static capitalLetterFirst(texto: string): string {
    if(!texto) return '';
    texto = texto.toLowerCase();
    return texto.charAt(0).toUpperCase() + texto.slice(1);
  }

  public static ignorarTildes(texto: string): string{
    return texto.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  /**
   * @param fecha date class Dayjs
   * @returns boolean: true if param is today date (without hour), false otherwise
   */
  public static esHoy(fecha:dayjs.Dayjs): boolean {
    return fecha.format('YYYY-MM-DD') === dayjs(new Date()).format('YYYY-MM-DD');
  }

  /**
   * @param fecha date class Date
   * @returns boolean: true if param is today date (without hour) or greater, false otherwise
   */
  public static esHoyOFuturo(fecha:Date): boolean {
    return fecha.getTime() >= new Date().getTime();
  }

  /**
   * @param fecha date class Dayjs
   * @param formatoSalida string format for Dayjs 
   * @returns yesterday full date in format YYYY-MM-DD HH:mm:ss'
   */
  public static cambiarHoyPorAyer(fecha: dayjs.Dayjs, formatoSalida:string): string{
    const esHoy = this.esHoy(fecha);
    return esHoy ? fecha.add(-1,'day').format(formatoSalida) : fecha.format(formatoSalida);
  }
  
  // /**
  //  * Método que permite crear una lista con un item por defecto
  //  * @returns Lista item defecto
  //  */
  // static itemDefecto(): IItem[] {
  //   return [{
  //     Clave: null,
  //     Valor: EMensaje.ErrorSinResultados
  //   }]
  // }

  /**
  * Función para agrupar los elementos duplicados por cierta propiedad y concatenar los valores de otra propiedad, ordenándolos alfabéticamente 
  * @param data array a agrupar
  * @param propGroup propiedad por la que se agrupa 
  * @param propToConcat propiedad que debe concatenarse
  * @returns array agrupado
   */
  public static agruparDuplicadosPorPropiedadYConcatenarValoresOtraPropiedad<T>(data: T[], propGroup: keyof T, propToConcat: keyof T): 
  Map<any,any[]> {
    const groupedMap  = new Map<any, any[]>();
    // Agrupar nombres por una propiedad y ordenarlos alfabéticamente por otra propiedad
    data.forEach((item) => {
      if (!groupedMap.has(item[propGroup])) {
          groupedMap.set(item[propGroup], []);
      }
      groupedMap.get(item[propGroup])?.push(item[propToConcat]);
  });
  console.log('groupedMAP: ', groupedMap);
  // Crear un nuevo array con los elementos agrupados y los valores de la otra propiedad concatenados
  
  
  return groupedMap;
  
  }


  // static filtrarDuplicadosPrefijos(array: IPais[]): IPais[] {
  //   const groupedMap = new Map<number, string[]>();
  
   
  //   array.forEach(pais => {
  //       if (!groupedMap.has(pais.prefix)) {
  //           groupedMap.set(pais.prefix, []);
  //       }
  //       groupedMap.get(pais.prefix)?.push(pais.name);
  //   });
    
  
  //   const result: IPais[] = [];
  //   groupedMap.forEach((names, prefix) => {
  //       result.push({
  //           id: null,
  //           name: names.sort().join(', '),
  //           prefix: prefix,
  //           alpha2: null,
  //           alpha3: null,
  //           lat: null,
  //           long: null
  //       });
  //   });
    
  //   return result;
  // }

}
