export const environment = {
  production: false,
  recursos: `https://webapp.destinux.com/recursos_online/destinux_test/`,
  generalURL: 'https://apitest-destinux.consultiatravel.es/',
  test: true,
  pruebas: false,
  demo: false,
  show: false,
  baseUrl: "https://webapp.destinux.com"
};
